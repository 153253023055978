/* 
Nested routes added as children to workspace route
ref : https://reactrouter.com/en/main/start/tutorial#nested-routes
*/
import CurrencyPrices from "./buyandsell/currencyprices/currencyprices";
import Fees from "./buyandsell/fees/fees";
import Limits from "./buyandsell/limits/limits";
import PairPrices from "./buyandsell/pairprices/pairprices";
import Dashboard from "./dashboard/dashboard";
import Reports from "./reports/reports";
import Settings from "./settings/settings";

const workspaceRoutes = [
	{ index: true, element: <Dashboard /> },

	{
		path: "dashboard",
		element: <Dashboard />,
	},
	{
		path: "reports",
		element: <Reports />,
	},
	{
		path: "settings",
		element: <Settings />,
	},
	{
		path: "buyandsell/currencyprices",
		element: <CurrencyPrices />,
	},
	{
		path: "buyandsell/pairprices",
		element: <PairPrices />,
	},
	{
		path: "buyandsell/fees",
		element: <Fees />,
	},
	{
		path: "buyandsell/limits",
		element: <Limits />,
	},
];

export default workspaceRoutes;
