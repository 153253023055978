import axios from "axios";

const getApiDomain = () => {
  const origin = location.origin;
  switch (origin) {
    case "https://admin-dev.muda-v2.com":
      return "https://admin-dev-api.muda-v2.com";
    case "https://admin-staging.muda-v2.com":
      return "https://admin-staging-api.muda-v2.com";
    case "https://admin.muda-v2.com":
      return "https://admin-api.muda-v2.com";
    default:
      return "http://127.0.0.1:8787";
    // return "https://dev-api.muda-v2.com";
  }
};

const login = async (creds) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/signin`, creds);
};

const verifyToken = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/verify/token`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRates = async () => {
  const domain = getApiDomain();
  return axios.get(`${domain}/rates`);
};

const updateRate = async ({ token, code, rate }) => {
  const domain = getApiDomain();
  const envelope = {
    currencyCode: code,
    newRate: rate
  }
  return axios.post(`${domain}/rates`, envelope, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getPairPrices = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/pairprices`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updatePairPrice = async ({ token, pair, markup, markdown, enabled }) => {
  const domain = getApiDomain();
  const envelope = {
    currencyPair: pair,
    markup,
    markdown,
    enabled
  }
  return axios.post(`${domain}/pairprices`, envelope, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};


const api = {
  getApiDomain,
  login,
  verifyToken,
  getRates,
  updateRate,
  getPairPrices,
  updatePairPrice
};

export default api;
